import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout';
import { Button, Table, Radio } from 'antd';
import './VencimentoCartao.css'
import { retornarCartoes } from './VencimentoCartaoF';
import * as XLSX from 'xlsx';


class VencimentoCartao extends React.Component {

    state = {
        loading: true,
        columns: [
			{
				key: 'beneficiario',
				title: 'Beneficiário',
				dataIndex: 'beneficiario',
			},
			{
				key: 'convenio',
				title: 'Convênio',
				dataIndex: 'convenio.nome',
			},
			{
				key: 'cnpj',
				title: 'CNPJ',
				dataIndex: 'convenio.cnpj',
			},
			{
				key: 'numero',
				title: 'Número',
				dataIndex: 'numero',
			},
			{
				key: 'dv',
				title: 'DV',
				dataIndex: 'dv',
			},
			{
				key: 'impresso',
				title: 'Impresso',
				dataIndex: 'impresso',
			},
			{
				key: 'vencimento',
				title: 'Vencimento',
				dataIndex: 'vencimento',
			},
			{
				key: 'status',
				title: 'Status',
				dataIndex: 'status',
			},
		],
        tableData: [],
        vencimento: 90,
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onFiltroChange = this.onFiltroChange.bind(this);
        this.exportExcell = this.exportExcel.bind(this);
    }

    exportExcel() {
        const dataCopy = this.state.tableData.map(item => ({
            beneficiario: `${item.beneficiario}`,
            convenio: `${item.convenio.nome}`,
            cnpj: `${item.convenio.cnpj}`,
            numero: `${item.numero}`,
            dv: `${item.dv}`,
            impresso: `${item.impresso}`,
            vencimento: `${item.vencimento}`,
            status: `${item.status}`,
        }));

        dataCopy.forEach(item => delete item.key);
        dataCopy.forEach(item => delete item.beneficiarioId);

        const headers = {
            beneficiario: "Beneficiário",
            convenio: 'Convênio',
            cnpj: "CNPJ",
            numero: "Número",
            dv: "DV",
            impresso: "Impresso",
            vencimento: "Vencimento",
            status: "Status",
        };

        const formattedData = dataCopy.map(item => ({
            [headers.beneficiario]: item.beneficiario,
            [headers.convenio]: item.convenio,
            [headers.cnpj]: item.cnpj,
            [headers.numero]: item.numero,
            [headers.dv]: item.dv,
            [headers.impresso]: item.impresso,
            [headers.vencimento]: item.vencimento,
            [headers.status]: item.status,
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Vencimento de Cartões.xlsx");
    }
    
    onFiltroChange(e) {
        this.setState({ vencimento: e.target.value });
    }


    async componentDidMount() {
        this.setState({ loading: false });

        // const userCol = currentUserCol();
        // const userType = await getUserType();
        // if (userCol || userType !== 'Gestor') {
        //     const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-movimentacao');
        //     if (!isOk) {
        //         this.setState({ loading: false });
        //         await mensagem.avisar('Você não tem permisão para acessar essa página.');
        //         history.push('/dashboard');
        //         history.go();
        //         return;
        //     }
        // }
    }

    async onFilterClick() {
		this.setState({loading: true});
        const cartoes = await retornarCartoes(this.state.vencimento);
		this.setState({tableData: cartoes, loading: false});
    }

    render() {
        return (
            <div>
                <PageLayout selectItem={"relatorios/movimentacao"}>
                    <div className="containerMovimentacao">
                        <label>A vencer em até:</label>
                        <Radio.Group onChange={this.onFiltroChange} defaultValue={this.state.vencimento}>
                            <Radio.Button value={90}>3 Meses</Radio.Button>
                            <Radio.Button value={180}>6 Meses</Radio.Button>
                        </Radio.Group>
                        &nbsp;
                        <Button loading={this.state.loading} onClick={this.onFilterClick} type="primary">Filtrar</Button>
                        &nbsp;
                        <Button disabled={this.state.tableData.length === 0} onClick={() => this.exportExcel()}>Exportar para Excel</Button>
                    </div>
                    <div className="tableEstabelecimentos">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                        >

                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(VencimentoCartao);

