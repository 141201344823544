import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';
import { formatNumber, unformatNumber } from '../components/cartao/card-provider';


async function queryBy10(ownerId, ids, impresso, fromCache) {
  let query = fbDatabase
    .collection('cartoes')
    .where('ownerId', '==', ownerId)
    .where('impressao.impresso', '==', impresso)
    .where('impressao.ignorado', '==', false);

  if (ids.length > 0) {
    query = query.where('convenio.id', 'in', ids);
  }

  const snapshot = await new DataManager().getQueryData(query, fromCache);

  if (snapshot.empty) {
    return [];
  }

  const data = snapshot.docs.map((item) => ({
    key: item.id,
    ...item.data(),
  }));

  return data;
}


class CartaoDB extends DataManager {

  state = {
    collection: 'cartoes',
    orderBy: [], // usado na classe pai
  }

  async getAllTemporary() {
    const query = fbDatabase.collection(this.state.collection);

    const snapshot = await this.getQueryData(query, false);

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));

    return data;
  }

  async getCartaoByNumeroAndOwnerId(numero, ownerId) {
    const query = fbDatabase
      .collection(this.state.collection)
      .where('ownerId', '==', ownerId)
      .where('numero', '==', numero);

    const snapshot = await this.getQueryData(query, false);

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
      validoAteBr: item.data().validAt.toDate().toLocaleDateString(),
      cartaoFormatado: formatNumber(item.data().numero),
    }));

    return data;
  }

  async getCartaoByNumero(numero) {
    const cardNumber = unformatNumber(numero);

    const query = fbDatabase
      .collection(this.state.collection)
      .where('numero', '==', cardNumber);

    const snapshot = await this.getQueryData(query, false);

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
      validoAteBr: item.data().validAt.toDate().toLocaleDateString(),
      cartaoFormatado: formatNumber(item.data().numero),
    }));

    return data;
  }

  async getByConveniosIds(ownerId, conveniosIds, impresso) {
    let ids = [];
    const cartoes = [];

    // Quando não tive nada, vai pegar todos do ownerId
    if (conveniosIds.length === 0) {
      const data = await queryBy10(ownerId, ids, impresso, false);
      cartoes.push.apply(cartoes, data);
      return cartoes;
    }

    for (let index = 0; index < conveniosIds.length; index++) {
      ids.push(conveniosIds[index]);

      if (ids.length >= 10) {
        const data = await queryBy10(ownerId, ids, impresso, false);
        cartoes.push.apply(cartoes, data);
        ids = [];
      }
    }

    if (ids.length > 0) {
      const data = await queryBy10(ownerId, ids, impresso, false);
      cartoes.push.apply(cartoes, data);
    }

    return cartoes;
  }

  async getByBeneficiarioId(beneficiarioId) {
    const query = fbDatabase
      .collection(this.state.collection)
      .where('beneficiarioId', '==', beneficiarioId);

    const snapshot = await this.getQueryData(query, false);

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
      validoAteBr: item.data().validAt.toDate().toLocaleDateString(),
      cartaoFormatado: formatNumber(item.data().numero),
    }));

    return data;
  }

  async getAVencer(ownerId, dias) {
    var validAt = new Date();
    validAt.setDate(validAt.getDate() + dias);

    const query = fbDatabase
      .collection(this.state.collection)
      .where('ownerId', '==', ownerId)
      .where('validAt', '<=', validAt)
	  .orderBy('validAt', 'asc');

    const snapshot = await this.getQueryData(query, false);

    if (snapshot.empty) {
      return [];
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));

    return data;
  }  
}

const cartaoDB = new CartaoDB();
export default cartaoDB;