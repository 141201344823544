import React, { Component } from 'react';
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import { Button, Table } from 'antd';
import { getTableData } from './SaldoConveniosF';
import { getColor } from '../../../components/layout/LayoutCSS';

import PageLayout from '../../../components/layout/PageLayout';
import history from '../../../history';
import mensagem from '../../../components/messages/message';
import funcs from '../../../components/funcs/funcs';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';

import 'jspdf-autotable';
import './SaldoConvenios.css';


const COLUMNS = [
    {
        title: 'Convênio',
        dataIndex: 'convenio',
        key: 'convenio'
    },
    {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj'
    },
    {
        title: 'Saldo',
        dataIndex: 'saldo',
        key: 'saldo'
    }
]


class SaldoConvenios extends Component {

    state = {
        loading: false,
        columns: COLUMNS,
        tableData: [],
        saldoTotal: 'R$ 0,00'
    }

    constructor(props) {
        super(props);
        this.exportPDF = this.exportPDF.bind(this);
        this.gerarRelatorio = this.gerarRelatorio.bind(this);
        this.exportExcell = this.exportExcel.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();
        const userType = await getUserType();

        if (userCol || userType !== 'Gestor') {
            debugger;
            const isOk = await funcs.podeAbrirRelatorio(userCol, '/relatorios/saldo-convenios');

            if (!isOk) {
                await mensagem.avisar('Você não tem permisão para acessar essa página.');

                history.push('/dashboard');
                history.go();

                return;
            }
        }
    }


    exportExcel() {

        const dataCopy = this.state.tableData.map(item => ({
            convenio: `${item.convenio}`,
            cnpj: `${item.cnpj}`,
            saldo: `${item.saldo}`,
        }));

        dataCopy.forEach(item => delete item.key);

        const headers = {
            convenio: "Convênio",
            cnpj: 'CNPJ',
            saldo: "Saldo",
        };

        const formattedData = dataCopy.map(item => ({
            [headers.convenio]: item.convenio,
            [headers.cnpj]: item.cnpj,
            [headers.saldo]: item.saldo,
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Saldo Convênios.xlsx");
    }

    async gerarRelatorio() {
        this.setState({ loading: true });

        const {
            tableData,
            saldoTotal
        } = await getTableData();

        this.setState({
            tableData,
            saldoTotal,
            loading: false
        });
    }

    exportPDF() {
        if (this.state.tableData.length === 0) {
            mensagem.avisar('Não há dados para exportar')
            return;
        }

        const unit = 'pt';
        const size = 'A4';
        const orientation = 'landscape';

        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const {
            tableData,
            saldoTotal
        } = this.state;

        const source = [];

        for (let i = 0; i < tableData.length; i++) {
            const {
                convenio,
                saldo
            } = tableData[i];

            const item = {
                convenio,
                saldo
            };

            source.push(item);
        }

        source.push({
            convenio: 'Total',
            saldo: saldoTotal
        });

        const data = source.map(elt => [elt.convenio, elt.saldo]);

        const title = 'Relatório de Saldo dos Convênios';
        const headers = [['Convênio', 'Saldo']];
        const marginLeft = 40;

        doc.text(title, marginLeft, 40);

        doc.autoTable({
            startY: 50,
            head: headers,
            body: data
        });

        doc.save('Relatório de Saldo dos Convênios.pdf');
    }

    render() {
        return (
            <PageLayout selectItem='relatorios/saldo-convenios'>
                <Button
                    type='primary'
                    disabled={this.state.loading || this.state.tableData.length > 0}
                    onClick={() => this.gerarRelatorio()}
                    style={{ marginRight: '1.25rem' }}
                >
                    Gerar Relatório
                </Button>

                <Button
                    disabled={this.state.loading || this.state.tableData.length === 0}
                    onClick={() => this.exportPDF()}
                >
                    Exportar para PDF
                </Button>
                &nbsp;

                <Button
                    onClick={() => this.exportExcel()}
                    disabled={this.state.loading || this.state.tableData.length === 0}
                >Exportar para Excel</Button>

                <Table
                    title={() => (
                        <div
                            className='cardSaldoTotal'
                            style={{ backgroundColor: getColor() }}
                        >
                            <div className='cardLabel'>
                                Saldo Total:
                            </div>

                            <div className='cardValue'>
                                {this.state.saldoTotal}
                            </div>
                        </div>
                    )}

                    dataSource={this.state.tableData}
                    columns={this.state.columns}
                    loading={this.state.loading}

                    pagination={{
                        defaultPageSize: 200,
                        position: 'both',
                        pageSizeOptions: ['25', '50', '100', '200'],
                        showSizeChanger: true,
                        locale: { items_per_page: '' }
                    }}
                />
            </PageLayout>
        )
    }
}

export default SaldoConvenios;