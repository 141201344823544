import ls from 'local-storage'
import vertteStorage from '../localstorage/localstorage';
import DataManager from '../../dataManager/dtm';
import { fbDatabase } from '../../firebase/firebase';


export function authenticate(user, userCollection) {
    ls.set('user', user);

    if (userCollection) {
        ls.set('user-col', userCollection);
    }
}

export function logout() {
    ls.set('user', null);
    ls.set('user-col', null);
    vertteStorage.clearAll();
}

export function currentUserCol() {
    let user = ls.get('user-col');
    return user;
}

export function currentUser() {
    let user = ls.get('user');
    return user;
}

export function isLoggedIn() {
    const user = currentUser();
    return user !== null && user !== undefined;
}

export async function getOwnerId() {
    const user = currentUser();
    const userType = await getUserType();

    if (userType === 'Gestor') {
        // Quando o usuário for um gestor, verifico se ele é o owner
        // checando se tem o user collection. O user collection indica que é um gestor
        // e não o owner
        const userCol = currentUserCol();

        if (userCol) {
            return userCol.ownerId;
        }

        return user.uid;
    }

    let gestorId = ls.get('reginamais-user-gestorId');

    if (!gestorId) {
        const usuario = ls.get('user-col');

        if (usuario) {
            gestorId = usuario.ownerId;
        } else {
            return undefined;
        }
    }

    return gestorId;
}

export function getGerenteId() {
    return ls.get('reginamais-user-gerenteId');
}

export async function getUserType() {
    const dtm = new DataManager();
    const { uid } = ls.get('user');

    const query = fbDatabase
        .collection('usuarios')
        .where('uid', '==', uid);

    const snapshot = await dtm.getQueryData(query, true);
    return snapshot.docs[0].data().tipo;
}

export async function isGestor() {
    return await getUserType() === 'Gestor';
}

export async function isAdministrador() {
    return await getUserType() === 'Administrador';
}

export async function getCustomerPermissions() {
    const dtm = new DataManager();
    const ownerId = await getOwnerId();

    const query = fbDatabase
        .collection('clientes')
        .where('uid', '==', ownerId);

    const snapshot = await dtm.getQueryData(query, true);
    return snapshot.docs[0] ? snapshot.docs[0].data().permissoes : ['debitoCredito'];
}

export async function isDebitPresent() {
    const permissions = await getCustomerPermissions();
    return permissions ? permissions.includes('debitoCredito') : false;
}

export async function isCashbackPresent() {
    const permissions = await getCustomerPermissions();
    return permissions ? permissions.includes('cashback') : false;
}

export async function isLoyaltyPresent() {
    const permissions = await getCustomerPermissions();
    return permissions ? permissions.includes('fidelidade') : false;
}

