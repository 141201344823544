import convenioDB from "../../../dataManager/dtmConvenio";
import { getOwnerId } from "../../../components/auth/auth-provider";
import beneficiarioDB from "../../../dataManager/dtmBeneficiario";
import movimentoDB from "../../../dataManager/dtmMovimentos";

const INDETERMINADO = "Indeterminado";

export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function getConvenios() {
  const ownerId = await getOwnerId();
  const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
  return convenios;
}

function getTipoMovimento(movimento) {
  if (movimento.credito > 0) {
    return "Crédito";
  }

  if (movimento.creditoPre > 0) {
    return "Recarga Desc Folha";
  }

  if (
    movimento.valorDebitadoSaldo !== undefined &&
    movimento.valorDebitadoSaldo > 0 &&
    movimento.valorDebitadoSaldoPre > 0
  ) {
    return "Compra com Débito e Crédito";
  }

  if (
    movimento.valorDebitadoSaldoPre !== undefined &&
    movimento.valorDebitadoSaldoPre > 0
  ) {
    return "Compra a Crédito";
  }

  return movimento.debitoVia === 'captura manual' ? 'Débito Manual' : 'Débito';
}

export async function getData(conveniosIds, dataIni, dataFim) {
  const ownerId = await getOwnerId();
  const inicio = dataIni.startOf("day").toDate();
  const fim = dataFim.endOf("day").toDate();
  const movimentos = await movimentoDB.getByPeriodo(
    inicio,
    fim,
    ownerId,
    conveniosIds
  );

  let beneficiarios = [];

  for (let index = 0; index < movimentos.length; index++) {
    const movimento = movimentos[index];

    let beneficiario = beneficiarios.filter((item) => {
      return item.id === movimento.beneficiarioId;
    })[0];

    if (!beneficiario) {
      //  Sempre que houver os dois tipos num movimento, crio dois childrens
      if (movimento.valorDebitadoSaldo && movimento.valorDebitadoSaldoPre > 0) {
        // Compra a débito
        let valor = beneficiarioDB.formatarMoeda(
          movimento.valorDebitadoSaldo / 100
        );
        const item = {
          key: `${movimento.key}-111`,
          valor,
          data: movimento.data
            .toDate()
            .toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
            .slice(0, 10)
            .replaceAll("/", "-"),
          tipoPagto: "Débito",
          pdvId: movimento.autorizacao ? movimento.autorizacao.pdvId : "",
          userName: movimento.autorizacao ? movimento.autorizacao.userName : "",
          estabelecimento: movimento.autorizacao
            ? movimento.autorizacao.estabelecimento.nomeFantasia
            : "",
        };
        beneficiario.children.push(item);

        // Compra a crédito
        valor = beneficiarioDB.formatarMoeda(
          movimento.valorDebitadoSaldoPre / 100
        );
        const itemb = {
          key: `${movimento.key}-111b`,
          valor,
          data: movimento.data
            .toDate()
            .toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
            .slice(0, 10)
            .replaceAll("/", "-"),
          tipoPagto: "Compra a Crédito",
          pdvId: movimento.autorizacao ? movimento.autorizacao.pdvId : "",
          userName: movimento.autorizacao ? movimento.autorizacao.userName : "",
          estabelecimento: movimento.autorizacao
            ? movimento.autorizacao.estabelecimento.nomeFantasia
            : "",
        };
        beneficiario.children.push(itemb);
      } else {
        let valor =
          movimento.debito > 0
            ? movimento.debito
            : movimento.credito > 0
            ? movimento.credito
            : movimento.creditoPre;
        valor = beneficiarioDB.formatarMoeda(valor / 100);
        beneficiario = {
          key: movimento.key,
          id: movimento.beneficiarioId,
          nome: movimento.beneficiario
            ? movimento.beneficiario.nome
            : INDETERMINADO,
          valor: "",
          // Não mude os nomes
          children: [
            {
              key: `${movimento.key}-111`,
              valor,
              data: movimento.data
                .toDate()
                .toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
                .slice(0, 10)
                .replaceAll("/", "-"),
              tipoPagto: getTipoMovimento(movimento),
              pdvId: movimento.autorizacao ? movimento.autorizacao.pdvId : "",
              userName: movimento.autorizacao
                ? movimento.autorizacao.userName
                : "",
              estabelecimento: movimento.autorizacao
                ? movimento.autorizacao.estabelecimento.nomeFantasia
                : "",
            },
          ],
        };
        beneficiarios.push(beneficiario);
      }
    } else {
      let tipoPagto = getTipoMovimento(movimento);

      //  Sempre que houver os dois tipos num movimento, crio dois childrens
      if (movimento.valorDebitadoSaldo && movimento.valorDebitadoSaldoPre > 0) {
        // Compra a débito
        let valor = beneficiarioDB.formatarMoeda(
          movimento.valorDebitadoSaldo / 100
        );
        const item = {
          key: `${movimento.key}-111`,
          valor,
          data: movimento.data
            .toDate()
            .toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
            .slice(0, 10)
            .replaceAll("/", "-"),
          tipoPagto: "Débito",
          pdvId: movimento.autorizacao ? movimento.autorizacao.pdvId : "",
          userName: movimento.autorizacao ? movimento.autorizacao.userName : "",
          estabelecimento: movimento.autorizacao
            ? movimento.autorizacao.estabelecimento.nomeFantasia
            : "",
        };
        beneficiario.children.push(item);

        // Compra a crédito
        valor = beneficiarioDB.formatarMoeda(
          movimento.valorDebitadoSaldoPre / 100
        );
        const itemb = {
          key: `${movimento.key}-111b`,
          valor,
          data: movimento.data
            .toDate()
            .toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
            .slice(0, 10)
            .replaceAll("/", "-"),
          tipoPagto: "Compra a Crédito",
          pdvId: movimento.autorizacao ? movimento.autorizacao.pdvId : "",
          userName: movimento.autorizacao ? movimento.autorizacao.userName : "",
          estabelecimento: movimento.autorizacao
            ? movimento.autorizacao.estabelecimento.nomeFantasia
            : "",
        };
        beneficiario.children.push(itemb);
      } else {
        let valor =
          movimento.debito > 0
            ? movimento.debito
            : movimento.credito > 0
            ? movimento.credito
            : movimento.creditoPre;
        valor = beneficiarioDB.formatarMoeda(valor / 100);

        const item = {
          key: `${movimento.key}-111`,
          valor,
          data: movimento.data
            .toDate()
            .toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
            .slice(0, 10)
            .replaceAll("/", "-"),
          tipoPagto,
          pdvId: movimento.autorizacao ? movimento.autorizacao.pdvId : "",
          userName: movimento.autorizacao ? movimento.autorizacao.userName : "",
          estabelecimento: movimento.autorizacao
            ? movimento.autorizacao.estabelecimento.nomeFantasia
            : "",
        };
        beneficiario.children.push(item);
      }

      const listaBnfs = beneficiarios.filter((item) => {
        return item.id !== movimento.beneficiarioId;
      });

      listaBnfs.push(beneficiario);
      beneficiarios = listaBnfs;
    }
  }

  for (let index = 0; index < beneficiarios.length; index++) {
    const beneficiario = beneficiarios[index];
    if (beneficiario.nome === INDETERMINADO) {
      const bnf = await beneficiarioDB.getById(beneficiario.id);
      beneficiarios[index].nome = `${bnf.nome.trim()} ${bnf.sobrenome
        .replace(/^\s+/, "")
        .trim()} (${beneficiario.children.length})`;
    } else {
      beneficiarios[
        index
      ].nome = `${beneficiarios[index].nome} (${beneficiarios[index].children.length})`;
    }
  }

  beneficiarios.sort((a, b) => (a.nome > b.nome ? 1 : -1));
  return beneficiarios;
}
