import { getOwnerId } from '../../../components/auth/auth-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import { getConvenios } from '../saldos/Funcs-Saldos';

export async function getTableData() {
    const convenios = await getConvenios();
    const tableData = [];
    let saldoTotal = 0;

    for (let i = 0; i < convenios.length; i++) {
        const {
            key,
            nomeFantasia,
            cnpj
        } = convenios[i];

        const saldo = await getSaldo(key);
        saldoTotal += saldo;

        tableData.push({
            key,
            saldo: formatCurrency(saldo),
            convenio: nomeFantasia,
            cnpj: cnpj ? cnpj : 'Não informado'
        });
    }

    return {
        tableData,
        saldoTotal: formatCurrency(saldoTotal)
    };
}

async function getBeneficiarios(ownerId, convenioId) {
    const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, convenioId);
    return beneficiarios;
}

async function getSaldo(convenioId) {
    const ownerId = await getOwnerId();
    const beneficiarios = await getBeneficiarios(ownerId, convenioId);
    let saldo = 0;

    for (let i = 0; i < beneficiarios.length; i++) {
        const { operacao } = beneficiarios[i];
        saldo += (operacao && operacao.saldo) ? operacao.saldo : 0;
    }

    return saldo;
}

function formatCurrency(valor) {
    valor = parseFloat(valor / 100).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });

    return valor;
}