import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout'
import { Select, Table, Button, message, Input, DatePicker } from 'antd';
import { getConvenios, getData } from './ExtratoF';
import vertteStorage from '../../../components/localstorage/localstorage';
import listObj from "../../../components/listSearch/listSearch";
import history from '../../../history';
import './Extrato.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import funcs from '../../../components/funcs/funcs';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const key = 'updatemessage';


class Extrato extends React.Component {

    state = {
        loading: false,
        columns: [],
        tableData: [],
        tableDataOriginal: [],
        convenio: {},
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        textFilter: 'Filtrar',
    }

    convenioOptions = [];

    constructor(props) {
        super(props);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.buildColumns = this.buildColumns.bind(this);
        this.onConvenioChange = this.onConvenioChange.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.updateList = this.updateList.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.exportExcell = this.exportExcel.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();
        const userType = await getUserType();
        if (userCol || userType !== 'Gestor') {
            const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-saldos');
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();
        for (const item of convenios) {
            this.convenioOptions.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        const storage = JSON.parse(vertteStorage.get('@reports/saldos'));

        if (storage) {
            this.setState({
                tableData: storage.tableData,
                tableDataOriginal: storage.tableData,
                convenio: storage.convenio,
                periodo: {
                    inicial: moment(storage.periodo ? storage.periodo.inicial : new Date()),
                    final: moment(storage.periodo ? storage.periodo.final : new Date()),
                },
            });

            this.buildColumns();
        }
    }

    // exportExcel() {
    //     debugger;
    //     const worksheet = XLSX.utils.json_to_sheet(this.state.tableData);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //     XLSX.writeFile(workbook, "Extrato.xlsx");
    // }

    exportExcel() {
        const dataToExport = [];
      
        this.state.tableData.forEach(item => {
          // Filtrar apenas as informações necessárias do objeto principal
          const mainData = {
            nome: item.nome,
          };
      
          // Processar os children, se existirem
          if (item.children && item.children.length > 0) {
            item.children.forEach(child => {
              // Filtrar apenas as informações necessárias de cada child
              const childData = {
                Data: child.data,
                Estabelecimento: child.estabelecimento,
                'Tipo de Pagamento': child.tipoPagto,
                'Operador de Caixa': child.userName,
                Valor: child.valor,
              };
      
              // Adicionar os dados do objeto principal (nome) aos dados do child
              const combinedData = { ...mainData, ...childData };
      
              // Adicionar os dados combinados ao array de exportação
              dataToExport.push(combinedData);
            });
          } else {
            // Se não houver children, adicionar apenas os dados do objeto principal
            dataToExport.push(mainData);
          }
        });
      
        // Criar a planilha
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      
        // Adicionar a planilha ao livro (workbook)
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
        // Salvar o arquivo Excel
        XLSX.writeFile(workbook, "Extrato.xlsx");
      }
      


    exportPDF() {

        if (this.state.tableData.length === 0) {
            message.warn({ content: 'Não há dados para exportar', key });
            return;
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const dataIni = this.state.periodo.inicial._d.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).replace(' 00:00:00', '');

        const dataFim = this.state.periodo.final._d.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).replace(' 23:59:59', '');;

        const title = `Extrato de ${dataIni} até ${dataFim} - Convênio: ${this.state.convenio.nome}`;
        const headers = [['Beneficiário', 'Data', 'Valor', 'Tipo Pagto', 'Estabelecimento', 'Pdv', 'Operador (a)']];

        const source = [];

        for (let index = 0; index < this.state.tableData.length; index++) {
            const bnf = this.state.tableData[index];
            const item = {
                nome: bnf.nome,
                valor: '',
                data: '',
                tipoPagto: '',
                estabelecimento: '',
                pdvId: '',
                userName: '',
            }
            source.push(item);

            for (let idx = 0; idx < bnf.children.length; idx++) {
                const mov = bnf.children[idx];

                const item = {
                    nome: '',
                    valor: mov.valor,
                    data: mov.data,
                    tipoPagto: mov.tipoPagto,
                    estabelecimento: mov.estabelecimento,
                    pdvId: mov.pdvId,
                    userName: mov.userName,
                }
                source.push(item);
            }
        }

        let totalStr = this.state.tableData.length > 1 ? ' registros' : this.state.tableData.length === 1 ? ' registro' : '';
        totalStr = `${this.state.tableData.length} ${totalStr}`;

        const total = {
            nome: `Total: ${totalStr}`,
            valor: '',
            data: '',
            tipoPagto: '',
            estabelecimento: '',
            pdvId: '',
            userName: '',
        }
        source.push(total);

        const data = source.map(elt => [elt.nome, elt.data, elt.valor, elt.tipoPagto, elt.estabelecimento, elt.pdvId, elt.userName]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("extrato.pdf")
    }

    onDateChange(value) {

        const periodo = {
            inicial: value[0],
            final: value[1]
        }

        const timeDiff = periodo.final._d.getTime() - periodo.inicial._d.getTime();
        const dayDiff = (timeDiff / (1000 * 3600 * 24)) + 1;
        if (dayDiff > 31) {
            message.error({ content: 'Período não pode ser maior que 30 dias', key });
            periodo.inicial = undefined;
            periodo.final = undefined;
        }

        this.setState({ periodo, alterouConfiguracaoRelatorio: true });
    }

    async onFilterClick() {
        if (!this.state.convenio.id) {
            message.warning({ content: 'Selecione um convênio', key });
            return;
        }

        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning({ content: 'Selecione um período', key });
            return;
        }

        this.setState({ loading: true, textFilter: 'Aguarde...', tableData: [] });
        this.buildColumns();

        const data = await getData(this.state.convenio.id, this.state.periodo.inicial, this.state.periodo.final);
        const storageElement = {
            tableData: data,
            convenio: this.state.convenio,
            periodo: this.state.periodo,
        }

        vertteStorage.set('@reports/saldos', JSON.stringify(storageElement));
        this.setState({ tableData: data, tableDataOriginal: data, loading: false, textFilter: 'Filtrar' });
    }

    onConvenioChange(value, obj) {
        const convenio = {
            id: value,
            nome: obj.props.children
        }

        this.setState({ convenio })
    }

    buildColumns() {
        const columns = [
            {
                title: 'Beneficiário',
                dataIndex: 'nome',
                key: 'nome',
            },

            {
                title: 'Data',
                dataIndex: 'data',
                key: 'data',
                width: '150px',
            },

            {
                title: 'Valor',
                dataIndex: 'valor',
                key: 'valor',
                align: 'right',

                render: (text, record) => (
                    <label className={record.tipoPagto === 'Débito' || record.tipoPagto === 'Compra a Crédito' ? 'red' : 'green'}>{text}</label>
                ),
            },
            {
                title: 'Tipo Pagto',
                dataIndex: 'tipoPagto',
                key: 'tipoPagto',
            },
            {
                title: 'Estabelecimento',
                dataIndex: 'estabelecimento',
                key: 'estabelecimento',
            },
            {
                title: 'Pdv',
                dataIndex: 'pdvId',
                key: 'pdvId',
            },
            {
                title: 'Operador (a)',
                dataIndex: 'userName',
                key: 'userName',
                //width: '300px',
            },
        ]

        this.setState({ columns })
    }

    async applyFilter(text) {
        if (text === '') {
            this.setState({ tableData: this.state.tableDataOriginal });
            return;
        };

        const keys = [
            'beneficiario.nome',
            'beneficiario.ultimoCredito',
            'beneficiario.saldoAtual',
        ];

        const data = listObj.search(this.state.tableDataOriginal, text, keys);
        this.setState({ tableData: data });
    }

    updateList(record) {
        let data = this.state.tableData;
        let registroEncontrado = false;

        if (data) {
            this.state.tableData.forEach((item, index) => {
                if (item.key === record.key) {
                    data[index] = record;
                    registroEncontrado = true;
                }
            });
        } else {
            data = []
        }

        if (!registroEncontrado) {
            data.push(record);
        }
        this.setState({ dataTable: data, dataTableOriginal: data });
    }


    render() {
        let totalStr = this.state.tableData.length > 1 ? ' registros' : this.state.tableData.length === 1 ? ' registro' : '';
        totalStr = `${this.state.tableData.length} ${totalStr}`;

        return (
            <PageLayout selectItem="relatorios/extrato">
                <div className="containerSaldos">
                    <label>Convênio:</label>
                    <Select
                        showSearch
                        disabled={this.state.loading}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }

                        style={{ width: '500px' }}
                        value={this.state.convenio.id}
                        onChange={this.onConvenioChange}
                    >
                        {this.convenioOptions}
                    </Select>

                    <label>Período: </label>
                    <RangePicker
                        style={{ width: '25%' }}
                        locale={locale}
                        disabled={this.state.loading}
                        format={dateFormat}
                        placeholder={['Data Inicial', 'Data Final']}
                        onChange={this.onDateChange}
                        value={[this.state.periodo.inicial, this.state.periodo.final]}
                    />
                    <Button type="primary" disabled={this.state.loading} onClick={this.onFilterClick}>{this.state.textFilter}</Button>
                </div>

                <div className="tableSaldos">
                    <Button onClick={() => this.exportPDF()}>Exportar para PDF</Button>
                    &nbsp;
                    <Button onClick={() => this.exportExcel()}>Exportar para Excel</Button>
                    <Table
                        title={() => (
                            <Header
                                ref={this.headerElement}
                                empresas={this.state.empresas}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                            />
                        )}
                        rowExpandable={true}
                        expandRowByClick={true}
                        loading={this.state.loading}
                        dataSource={this.state.tableData}
                        columns={this.state.columns}
                        rowKey={(record) => record.key}
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["25", "50", "100", "200"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                        footer={() => <div>
                            <p><strong>Total:</strong>{` ${totalStr}`}</p>
                        </div>}
                    >

                    </Table>
                </div>

            </PageLayout >
        )
    }

}

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
        this.filterTable = this.filterTable.bind(this);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {
        return (
            <div>
                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ width: 200 }}
                />

            </div>
        );
    }
}

export default withRouter(Extrato);