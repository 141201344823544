import { getOwnerId } from "../../../components/auth/auth-provider";
import { formatNumber } from "../../../components/cartao/card-provider";
import beneficiarioDB from "../../../dataManager/dtmBeneficiario";
import cartaoDB from "../../../dataManager/dtmCartao";
import convenioDB from "../../../dataManager/dtmConvenio";

async function retornarConvenio(convenioId) {
  const convenio = await convenioDB.getById(convenioId);
  return convenio;
}

async function retornarNomeBeneficiario(beneficiarioId) {
  const beneficiario = await beneficiarioDB.getById(beneficiarioId);
  return beneficiario.nome.trim() + ' ' + beneficiario.sobrenome.trim();
}

export async function retornarCartoes(dias) {
  const ownerId = await getOwnerId();
  const vencimento = new Date();
  vencimento.setDate(vencimento.getDate() + dias);

  const cartoes = await cartaoDB.getAVencer(ownerId, dias);
  const data = cartoes.map((item) => ({
    key: item.key,
    beneficiarioId: item.beneficiarioId,
    convenio: item.convenio,
    numero: formatNumber(item.numero),
    dv: item.dv,
    impresso: item.impressao.impresso ? 'Sim' : 'Não',
    status: item.status,
    vencimento: item.validAt.toDate().toLocaleDateString(),
  }));

  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    let conv = await retornarConvenio(item.convenio.id);
    item.convenio.cnpj = conv.cnpj;
    item.beneficiario = await retornarNomeBeneficiario(item.beneficiarioId);
  }

  return data;
}