
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

import {
    PROJECTS,
    IDX_MOUB,
    IDX_MOUB_DEV,
    IDX_CONVENIO
} from './projects';

import {
    FIREBASE_CACHE_CLEAR,
    H_01_HORA,
    H_24_HORAS
} from '../components/funcs/constants';

import ls from 'local-storage';
import vertteStorage from '../components/localstorage/localstorage';

const USE_LOCAL_API = false;
const PROJECT = PROJECTS[IDX_CONVENIO];
ls.set('project', PROJECT);

let config = {};

switch (true) {
    case PROJECT.id === IDX_MOUB:
        // MOUB PROJECT
        config = {
            apiKey: "AIzaSyCnDELxZ6UX1NCZbd0lblebV6ApnNOrGjg",
            authDomain: "moub-4c.firebaseapp.com",
            databaseURL: "https://moub-4c.firebaseio.com",
            projectId: "moub-4c",
            storageBucket: "moub-4c.appspot.com",
            messagingSenderId: "275586078746",
            appId: "1:275586078746:web:cf3d2eb01f594425a219a3",
            measurementId: "G-KZBWG8YLQ4"
        };
        break;

    case PROJECT.id === IDX_CONVENIO:
        // REGINA PROJECT
        config = {
            apiKey: "AIzaSyCJy1fxwk8pC3QZf1mXq395_ceMn2kVQvk",
            authDomain: "convenio-37457.firebaseapp.com",
            databaseURL: "https://convenio-37457.firebaseio.com",
            projectId: "convenio-37457",
            storageBucket: "convenio-37457.appspot.com",
            messagingSenderId: "1066587673440",
            appId: "1:1066587673440:web:0e6500a57ad7820aabc405",
            measurementId: "G-XF69M33XJJ"
        };
        break;

    default:
        config = {
            apiKey: "AIzaSyDQ8SDb6xFuOjIUlNUM7t2t5WIEPm3sRAw",
            authDomain: "payments-4c.firebaseapp.com",
            databaseURL: "https://payments-4c.firebaseio.com",
            projectId: "payments-4c",
            storageBucket: "payments-4c.appspot.com",
            messagingSenderId: "312148104664",
            appId: "1:312148104664:web:eed975ee78ac14fa638ead",
            measurementId: "G-8B4HJFJ1BM"
        }
        // do not remove
        console.log(IDX_MOUB_DEV);
        break;
}


export const fireBase = firebase.initializeApp(config);
export const fbDatabase = firebase.firestore();
export const fbFirestore = firebase.firestore;
export const Timestamp = firebase.firestore.Timestamp;
export const fieldPath = firebase.firestore.FieldPath;
export const CONF = PROJECT;

function getProject() {

    if (USE_LOCAL_API) {
        return `http://localhost:5001/${PROJECT.project}/us-central1/api/v2`;
    }
    return `https://us-central1-${PROJECT.project}.cloudfunctions.net/api/v2`;
}


export const API_ROUTER = `${getProject()}/`;
export const API_ROUTER_CHARGE = `${getProject()}/charge/`;
export const UPDATE_USER_URL = `${getProject()}/update-user`;
export const UPDATE_MAIL_USER_URL = `${getProject()}/update-mail-user`;
export const DELETE_USER_URL = `${getProject()}/delete-user`;
export const APPCONF = PROJECT;

if (!vertteStorage.getWithExpiry(FIREBASE_CACHE_CLEAR)) {
    fbDatabase.clearPersistence();

    const ttl = H_24_HORAS - new Date().getHours() * H_01_HORA;

    vertteStorage.setWithExpiry(
        FIREBASE_CACHE_CLEAR,
        true,
        ttl
    );
}

fbDatabase.enablePersistence()
    .catch((err) => {
        console.log(err);
    });