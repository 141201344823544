import React from 'react';
import PageLayout from '../../../components/layout/PageLayout';
import {
    getBeneficiarios,
    getMovimentacaoes,
    getEstabelecimentos,
    getConvenios
} from './Funcs-HistoricoMovimentos';
import {
    Select,
    DatePicker,
    Button,
    Table,
    Tabs,
    message,
} from 'antd';
import vertteStorage from '../../../components/localstorage/localstorage';
import moment from 'moment';
import history from '../../../history';
import './HistoricoMovimentos.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import funcs from '../../../components/funcs/funcs';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';


const key = 'keyhistoricomovimento';
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY'
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;


class HistoricoMovimentos extends React.Component {

    state = {
        convenioId: '',
        alterouConfiguracaoRelatorio: false,
        loading: true,
        periodo: {},
        beneficiarios: [],
        beneficiario: {},
        estabelecimento: {},
        columns: [],
        tableData: [],
        tipoTransacao: 'todas',
        tipoFiltro: 'beneficiarios',
    }

    beneficiariosOptions = [];
    estabelecimentosOptions = [];
    tipoTransacaoOptions = [];


    options = [];
    beneficiariosOptions = [];


    constructor(props) {
        super(props);

        this.onDateChange = this.onDateChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onSelectTipoTransacaoChange = this.onSelectTipoTransacaoChange.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.onSelectBeneficiarioChange = this.onSelectBeneficiarioChange.bind(this);
        this.exportToPDF = this.exportToPDF.bind(this);
        this.exportToExcell = this.exportToExcel.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();
        const userType = await getUserType();
        if (userCol || userType !== 'Gestor') {
            const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-historico-movimentacao');
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();
        if (!convenios) {
            return;
        }

        for (const item of convenios) {
            this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        const debitoOption = <Option key={"debito" + this.state.tipoFiltro} value="debito">Débito</Option>;
        const debitoDescFolhaOption = <Option key={"debitoDescFolha" + this.state.tipoFiltro} value="debitoDescFolha">Débito (Desc. Folha)</Option>;
        const creditoDescFolhaOption = <Option key="credito" value="credito">Crédito</Option>;
        const creditoOption = <Option key="creditoDescFolha" value="creditoDescFolha">Crédito (Desc. Folha)</Option>;
        const estornoOption = <Option key={"estorno" + this.state.tipoFiltro} value="estorno">Estorno</Option>;
        const todosOption = <Option key={"todas" + this.state.tipoFiltro} value="todas">Todas</Option>;

        this.tipoTransacaoOptions.push(debitoOption, debitoDescFolhaOption, creditoOption, creditoDescFolhaOption, estornoOption, todosOption);
        const storage = JSON.parse(vertteStorage.get('@reports/historicoMovimentos'));
        if (storage) {
            this.setState({
                convenioId: storage.convenioId,
                tipoFiltro: storage.tipoFiltro,
                beneficiario: storage.beneficiario,
                estabelecimento: storage.estabelecimento,
                tableData: storage.tableData,
                periodo: {
                    inicial: moment(storage.periodo.inicial),
                    final: moment(storage.periodo.final),
                },
                tipoTransacao: "todas"
            });

            for (const item of storage.beneficiariosOptions) {
                this.beneficiariosOptions.push(<Option key={item.key} value={item.key}>{item.props.children[0]} </Option>)
            }

            this.setState({ beneficiarios: storage.beneficiariosOptions });

            this.buildColumns();
            this.setState({ loading: false });
        } else {
            this.setState({ loading: false });            
        }
    }

    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }

        this.setState({ periodo });

    }

    async onSelectChange(convenioId, obj) {
        if (this.state.tipoFiltro === 'beneficiarios') {
            this.beneficiariosOptions = [];
            const b = await getBeneficiarios(convenioId);
            for (const item of b) {
                this.beneficiariosOptions.push(<Option key={item.key} value={item.key}>{item.nome + ' ' + item.sobrenome} </Option>)
            }
            this.setState({ beneficiarios: b, convenioId, alterouConfiguracaoRelatorio: true, beneficiario: {}, tableData: [] })
        } else {
            const item = {
                id: convenioId,
                nome: obj.props.children,
            }
            this.setState({ estabelecimento: item, alterouConfiguracaoRelatorio: true, beneficiario: {}, tableData: [] });
        }
    }

    onSelectBeneficiarioChange(value, object) {
        const beneficiario = {
            id: value,
            nome: object.props.children
        }
        this.setState({
            beneficiario,
            data: [],
            tableData: [],
        })
    }

    async onFilterClick() {
        if (this.state.tipoFiltro === 'beneficiarios' && !this.state.beneficiario.id) {
            message.warning({ content: 'Selecione um beneficiário.', key });
            return;
        } else if (this.state.tipoFiltro === 'estabelecimentos' && !this.state.estabelecimento.id) {
            message.warning({ content: 'Selecione um estabelecimento.', key });
            return;
        }

        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning({ content: 'Selecione um período.', key });
            return;
        }

        this.setState({ loading: true });
        this.buildColumns();

        const id = this.state.tipoFiltro === 'beneficiarios' ? this.state.beneficiario.id : this.state.estabelecimento.id;
        const data = await getMovimentacaoes(
            this.state.periodo.inicial,
            this.state.periodo.final,
            id,
            this.state.tipoFiltro,
            this.state.beneficiarios
        );

        this.setState({ tableData: data, loading: false });

        const storageElement = {
            tipoFiltro: this.state.tipoFiltro,
            beneficiario: this.state.beneficiario,
            beneficiariosOptions: this.beneficiariosOptions,
            estabelecimento: this.state.estabelecimento,
            tableData: this.state.tableData,
            periodo: this.state.periodo,
            tipoTransacaoOptions: this.tipoTransacaoOptions,
            convenioId: this.state.convenioId,
        }

        vertteStorage.set('@reports/historicoMovimentos', JSON.stringify(storageElement));
    }

    mergeArray = (data) => {

        return data;

        //comentado em 01 de abril de 2023. 
        // passado um tempo, se ninguém reclamar, descomenta.
        // o caso abaixo é aquele que quebra a visualização da grid, dando um péssimo visual 
        //e gerando encadeamento infinito dos dados à partir das datas iguais que ficam sendo
        //concatenadas

        // if (!data) {
        //     return;
        // }
        // return data.reduce((result, item) => {
        //     if (result.indexOf(item.data) < 0) {
        //         result.push(item.data)
        //     }

        //     return result
        // }, []).reduce((result, date) => {
        //     const children = data.filter((item) => item.data === date);

        //     result = result.concat(
        //         children.map((item, index) => ({
        //             ...item,
        //             rowSpan: (index + result.length) % 10 !== 0 ? (index === 0 ? children.length : 0) : children.length - index,
        //         }))
        //     )
        //     return result
        // }, []);
    }

    buildColumns() {
        let columns = [];
        const tipoFiltro = this.state.tipoFiltro === 'beneficiarios' ? true : false;

        columns = [
            {
                title: 'Data',
                dataIndex: 'data',
                key: 'data',
                sorter: (a, b) => {
                    let aa = a.data.split('/').reverse().join(),
                        bb = b.data.split('/').reverse().join();
                    return aa < bb ? -1 : (aa > bb ? 1 : 0);
                },
                render: (value, row) => {
                    return {
                        children: row.data,
                        props: {
                            rowSpan: row.rowSpan,
                        }
                    }
                },
            },
            {
                title: 'Horário',
                dataIndex: 'hora',
                key: 'hora',
            },
            {
                title: tipoFiltro ? 'Estabelecimento' : 'Beneficiário',
                dataIndex: tipoFiltro ? 'estabelecimento.nome' : 'beneficiario.nome',
                key: tipoFiltro ? 'estabelecimento.nome' : 'beneficiario.nome',
                sorter: tipoFiltro ?
                    (a, b) => {
                        if (a === '--' || b === '--') {
                            return -1;
                        } else return a.estabelecimento.nome.localeCompare(b.estabelecimento.nome)
                    } :
                    (a, b) => {
                        if (a === '--' || b === '--') {
                            return -1;
                        } else return a.beneficiario.nome.localeCompare(b.beneficiario.nome);
                    },
            },
        ];

        if (tipoFiltro) {
            columns.push(            {
                title: tipoFiltro ? 'CNPJ' : 'CPF',
                dataIndex: tipoFiltro ? 'estabelecimento.cnpj' : 'beneficiario.cpf',
                key: 'cnpjCpf',
            });
        }

        if (!tipoFiltro) {
            columns.push({
                title: 'Convênio',
                dataIndex: 'convenio.nome',
                key: 'convenio.nome',

                sorter: (a, b) => {
                    return a.convenio.nome.localeCompare(b.convenio.nome);
                }
            });
        }

        columns.push({
            title: 'Tipo de Transação',
            dataIndex: 'tipo',
            key: 'tipo'
        });

        columns.push({
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            sorter: (a, b) => a.valor.localeCompare(b.valor),
            render: (text, record) => {
                return {
                    props: {
                        style: { color: record.tipo.includes('Débito') ? "red" : "green" }
                    },
                    children: <div>{record.tipo.includes('Débito') ? '- ' + text : '+ ' + text}</div>
                };
            },
        });

        this.setState({ columns })
    }

    onSelectTipoTransacaoChange(value) {
        if (this.state.tableData[value] && this.state.tableData[value].length === 0) {
            message.warning("Não existem transações deste tipo para o período selecionado.")
        }
        this.setState({ tipoTransacao: value })
    }

    async changeTab(value) {
        this.setState({ tipoFiltro: value });

        if (value === 'estabelecimentos') {
            this.setState({ loading: true });

            const estabelecimentos = await getEstabelecimentos();
            for (let e of estabelecimentos) {
                this.estabelecimentosOptions.push(<Option key={e.key} value={e.key}>{e.nomeFantasia}</Option>)
            }

            this.setState({ tableData: [], loading: false });
        }
    }

    exportToExcel() {
        const exportar = [];
        if (this.state.tipoFiltro === 'beneficiarios') {

            exportar.push({
                "Convênio": this.state.estabelecimento.nome,
                "Beneficiário": this.state.beneficiario.nome[0] ? this.state.beneficiario.nome[0] : this.state.beneficiario.nome,
                "Período": this.state.periodo.inicial._d.toLocaleDateString() + ' até ' + this.state.periodo.final._d.toLocaleDateString()
            });

            exportar.push({});
            exportar.push({});

            const data = this.mergeArray(this.state.tableData[this.state.tipoTransacao]);
            data.forEach(item => {
                exportar.push({
                    "Data e Hora": item.data + ' ' + item.hora,
                    "Estabelecimento": item.estabelecimento.nome,
                    "Tipo de Transação": item.tipo,
                    "Valor": item.valor
                });
            });

            exportar.push({});
            exportar.push({});

            exportar.push({
                "Total de Créditos": this.state.tableData.totalCreditos,
                "Total de Débitos": this.state.tableData.totalDebitos,
            });
        } else {
            exportar.push({
                "Convênio": this.state.estabelecimento.nome,
                "Período": this.state.periodo.inicial._d.toLocaleDateString() + ' até ' + this.state.periodo.final._d.toLocaleDateString()
            });

            exportar.push({});
            exportar.push({});

            const data = this.mergeArray(this.state.tableData[this.state.tipoTransacao]);
            data.forEach(item => {
                exportar.push({
                    "Data e Hora": item.data + ' ' + item.hora,
                    "Beneficiário": item.beneficiario.nome,
                    "Tipo de Transação": item.tipo,
                    "Valor": item.valor
                });
            });
            exportar.push({});
            exportar.push({});

            exportar.push({
                "Total de Débitos": this.state.tableData.totalDebitos,
            });
        }

        const worksheet = XLSX.utils.json_to_sheet(exportar);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Histórico de Movimentos.xlsx");
    }

    exportToPDF() {
        const msg = 'Não há dados para exportar';
        if (this.state.tableData.length === 0) {
            message.warn({ content: msg, key });
            return;
        }

        const data = this.state.tableData[this.state.tipoTransacao];
        if (!data || data.length === 0) {
            message.warn({ content: msg, key });
            return;
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const dataIni = this.state.periodo.inicial._d.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).replace(' 00:00:00', '');

        const dataFim = this.state.periodo.final._d.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).replace(' 23:59:59', '');

        let title = '';
        let headers = [[]];

        if (this.state.tipoFiltro === 'beneficiarios') {
            title = `Hist. Movimento ${dataIni} até ${dataFim} - Beneficiário: ${this.state.beneficiario.nome[0]}`;
            headers = [['Data', 'Horário', 'Estabelecimento', 'Tipo Transação', 'Valor']];
        } else {
            title = `Hist. Movimento ${dataIni} até ${dataFim} - Estabelecimento: ${this.state.estabelecimento.nome}`;
            headers = [['Data', 'Horário', 'Beneficiário', 'Tipo Transação', 'Valor']];

        }
        const source = [];

        for (let index = 0; index < data.length; index++) {
            const reg = data[index];
            const item = {
                data: reg.data,
                horario: reg.hora,
                bnfOrEstab: this.state.tipoFiltro === 'beneficiarios' ? reg.estabelecimento.nome : reg.beneficiario.nome,
                tipoTransacao: reg.tipo,
                valor: reg.valor,
            }
            source.push(item);
        }

        const dataSource = source.map(elt => [elt.data, elt.horario, elt.bnfOrEstab, elt.tipoTransacao, elt.valor]);

        let content = {
            startY: 50,
            head: headers,
            body: dataSource
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        const fileName = `Histórico Movimento - ${this.state.tipoFiltro === 'beneficiarios' ? this.state.beneficiario.nome[0] : this.state.estabelecimento.nome}.pdf`
        doc.save(fileName);
    }

    render() {
        return (
            <PageLayout selectItem="relatorios/historico-movimentacao">

                <label>Filtrar por:</label>

                <Tabs activeKey={this.state.tipoFiltro} onChange={this.changeTab}>

                    <TabPane tab="Beneficiários" key="beneficiarios">

                        <label>Convênio: </label>
                        <Select

                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.loading}
                            optionFilterProp="children"
                            style={{ marginLeft: '6px', width: '500px' }}
                            placeholder='Selecione um convênio'
                            onChange={this.onSelectChange}
                            value={this.state.convenioId}
                        >{this.options}</Select>

                        <label style={{ marginLeft: 10, marginRight: 10 }}>Beneficiário: </label>
                        <Select
                            ref={(el) => this.beneficiarioRef = el}
                            disabled={this.state.loading}
                            showSearch
                            optionFilterProp="children"
                            style={{ width: '20%' }}
                            placeholder='Selecione um beneficiário'
                            onChange={this.onSelectBeneficiarioChange}
                            value={this.state.beneficiario.id}
                        >
                            {this.beneficiariosOptions}
                        </Select>
                    </TabPane>

                    <TabPane tab="Estabelecimentos" key="estabelecimentos">
                        <label>{'Estabelecimento:'}</label>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ marginLeft: '6px', width: '500px' }}
                            onChange={this.onSelectChange}
                            value={this.state.estabelecimento.nome ? this.state.estabelecimento.nome : ''}
                        >
                            {this.estabelecimentosOptions}
                        </Select>
                    </TabPane>

                </Tabs>

                <div className="containerHistoricoMovimento">
                    <label>Período: </label>
                    <RangePicker
                        onChange={this.onDateChange}
                        placeholder={['Data Inicial', 'Data Final']}
                        format={dateFormat}
                        style={{ marginTop: '30px', width: '22%' }}
                        value={[this.state.periodo.inicial, this.state.periodo.final]}
                    />

                    <label>Tipo de Transação: </label>
                    <Select
                        placeholder="Selecione o tipo de transação"
                        defaultValue="todas"
                        value={this.state.tipoTransacao}
                        onChange={this.onSelectTipoTransacaoChange}
                        style={{ width: '200px' }}
                    >
                        {this.tipoTransacaoOptions}
                    </Select>

                    <Button type="primary" loading={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>

                    <Button disabled={this.state.loading} icon="download" onClick={() => this.exportToPDF()}>Exportar para PDF</Button>
                    <Button disabled={this.state.loading} icon="download" onClick={() => this.exportToExcel()}>Exportar para Excel</Button>
                </div>

                <div className="tableHistoricoMovimentos">
                    <Table
                        dataSource={this.mergeArray(this.state.tableData[this.state.tipoTransacao])}
                        columns={this.state.columns}
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["25", "50", "100", "200"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                    />


                    {this.state.tipoFiltro === 'beneficiarios' ? (
                        <label><strong>Total de créditos: </strong>{this.state.tableData.totalCreditos}</label>
                    ) : (
                        ""
                    )}

                    <label><strong>Total de déditos: </strong>{this.state.tableData.totalDebitos}</label>
                </div>

            </PageLayout>
        )
    }

}

export default HistoricoMovimentos;