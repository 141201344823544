import React, { useState } from 'react';
import { Radio } from 'antd';
import moment from "moment";
import { getOwnerId } from '../auth/auth-provider';
import pontoDB from '../../dataManager/dtmPontos';
import beneficiarioDB from '../../dataManager/dtmBeneficiario';
import resgateDB from '../../dataManager/dtmResgate';
import { Line } from 'react-chartjs-2';

import './Styles.css';


const GraficoFidelidade = () => {
    const [carregando, setCarregando] = useState(false);
    const [labelButton, setLabelButton] = useState("Filtrar");
    const [periodo, setPeriodo] = useState("hoje");
    const [pontos, setPontos] = useState(0);
    const [resgates, setResgates] = useState(0);
    const [novosClientes, setNovosClientes] = useState(0);
    const [valoresMovimentados, setValoresMovimentados] = useState('R$ 0,00');
    const [snapshotPontos, setSnapshotPontos] = useState([]);
    const [snapshotResgates, setSnapshotResgates] = useState([]);
    const [snapShotBeneficiarios, setSnapshotBeneficiarios] = useState([]);

    const chartOptions = {

        plugins: {
            datalabels: {
                display: true,
                anchor: "end",
                align: "top",
                formatter: function (value, context) {
                    value = new Intl.NumberFormat('pt-BR', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(value);
                    return value;
                }
            },
        },        


        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {

                    let label = data.datasets[tooltipItem.datasetIndex].label;
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                    value = new Intl.NumberFormat('pt-BR', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(value);

                    return ' ' + label + ': ' + value
                }
            }
        },

        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    }

                }
            ]
        },
        layout: {
            padding: {
                top: 20,
                right: 40,
                left: 40,
                bottom: 20,
            }
        },
    };

    const chartOptionsPontosPorDia = {

        plugins: {
            datalabels: {
                display: true,
                anchor: "end",
                align: "top",
                formatter: function (value, context) {
                    value = new Intl.NumberFormat('pt-BR', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(value);
                    return value;
                }
            },
        },        


        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {

                    let label = data.datasets[tooltipItem.datasetIndex].label;
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                    value = new Intl.NumberFormat('pt-BR', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(value);

                    return ' ' + label + ': ' + value
                }
            }
        },

        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (value) {
                            return new Intl.NumberFormat('pt-BR', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(value);
                        },
                    },
                },
            ],
        },
        layout: {
            padding: {
                top: 20,
                right: 40,
                left: 40,
                bottom: 20,
            },
        },
    };

    const filtrar = async () => {
        setCarregando(true);
        setLabelButton("Carregando...");
        let dias = periodo === "hoje" ? 0 : periodo === "15dias" ? 14 : 29;

        let dataInicial = new Date();
        dataInicial.setDate(dataInicial.getDate() - dias);
        dataInicial = new Date(dataInicial.getFullYear(), dataInicial.getMonth(), dataInicial.getDate(), 0, 0, 0);
        dataInicial.setHours(0, 0, 0, 0);

        let dataFinal = new Date();
        dataFinal.setDate(dataFinal.getDate());
        dataFinal.setHours(23, 59, 59, 59);

        dataInicial = moment(dataInicial);
        dataFinal = moment(dataFinal);

        const ownerId = await getOwnerId();
        const snapshotPontos = await pontoDB.getByPeriodo(
            dataInicial._d, dataFinal._d, ownerId, undefined, false);

        const snapshotResgates = await resgateDB.getByPeriodo(
            dataInicial._d, dataFinal._d, ownerId, undefined, false);
        setResgates(snapshotResgates.length);
        setSnapshotResgates(snapshotResgates);

        const snapShotBeneficiarios = await beneficiarioDB.getByConvenioIdAndPeriodo(
            ownerId, dataInicial._d, dataFinal._d
        );
        setNovosClientes(snapShotBeneficiarios.length);
        setSnapshotBeneficiarios(snapShotBeneficiarios);
        const contadores = snapshotPontos.reduce((contador, ponto) => {
            contador.pontos += ponto.pontos;
            contador.valoresMovimentados += ponto.valueInCents;

            return contador;
        }, { pontos: 0, valoresMovimentados: 0 });

        const pontosFormatado = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(contadores.pontos);

        setPontos(pontosFormatado);
        setSnapshotPontos(snapshotPontos);
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });

        const valorEmReais = formatter.format(contadores.valoresMovimentados / 100);
        setValoresMovimentados(valorEmReais);
        setCarregando(false);
        setLabelButton("Filtrar");
    }

    const getDataForChart = (data) => {
        const hourlyCounts = Array(24).fill(0);

        data.forEach((item) => {
            const timestamp = item.data.seconds * 1000;
            const date = new Date(timestamp);
            const hour = date.getHours();

            hourlyCounts[hour] += 1;
        });

        return {
            labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
            datasets: [
                {
                    label: 'Pico de Horas',
                    data: hourlyCounts,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(95, 41, 100, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const formatCurrency = (value) => {
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
        return formatter.format(value);
    };


    const getDailyChartData = (data) => {
        const dailyData = {};

        data.forEach((item) => {
            const timestamp = item.data.seconds * 1000;
            const date = new Date(timestamp);
            const dateString = moment(date).format('DD/MM/YYYY');

            if (!dailyData[dateString]) {
                dailyData[dateString] = {
                    pontos: 0,
                    valoresMovimentados: 0,
                };
            }

            dailyData[dateString].pontos += item.pontos;
            dailyData[dateString].valoresMovimentados += item.valueInCents;
        });

        const labels = Object.keys(dailyData);
        const pontosData = labels.map((date) => dailyData[date].pontos.toFixed(2));

        return {
            labels,
            datasets: [
                {
                    label: 'Pontos',
                    data: pontosData,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(95, 41, 100, 1)',
                    borderWidth: 1,
                },
            ],
            options: {
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                            const value = tooltipItem.yLabel;
                            return `${datasetLabel}: ${formatCurrency(value)}`;
                        },
                    },
                },
            },
        };
    };

    return (
        <div className="container">
            <Radio.Group defaultValue="hoje" size="large" onChange={(e) => setPeriodo(e.target.value)}>
                <Radio.Button value="hoje">Hoje</Radio.Button>
                <Radio.Button value="15dias">15 dias</Radio.Button>
                <Radio.Button value="30dias">30 dias</Radio.Button>
            </Radio.Group>
            &nbsp;
            <Radio.Group defaultValue="a" size="large" disabled={carregando}>
                <Radio.Button value="a" onClick={filtrar} >{labelButton}</Radio.Button>
            </Radio.Group>


            <div className="row divCardsFidelidadeDashboard">

                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card-box bg-orange">
                        <div className="inner">
                            <h3>{pontos}</h3>
                            <p>Pontos</p>
                        </div>
                        <div className="icon">
                            <i className="fa fa-arrow-up" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card-box bg-green">
                        <div className="inner">
                            <h3>{resgates}</h3>
                            <p>Resgates</p>
                        </div>
                        <div className="icon">
                            <i className="fa fa-arrow-down" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card-box bg-purple">
                        <div className="inner">
                            <h3>{novosClientes}</h3>
                            <p>Novos Clientes</p>
                        </div>
                        <div className="icon">
                            <i className="fa fa-user" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card-box bg-red">
                        <div className="inner">
                            <h3>{valoresMovimentados}</h3>
                            <p>Valores Movimentados</p>
                        </div>
                        <div className="icon">
                            <i className="fa fa-calendar" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-4">
                            <h4 className="header-title">Pico de Horas</h4>
                            <div className="mt-4">
                                <Line data={getDataForChart(snapshotPontos)} options={chartOptions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {periodo !== "hoje" ? (

                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-4">
                                <h4 className="header-title">Pontuações por Dia</h4>
                                <div className="mt-4">
                                    <Line data={getDailyChartData(snapshotPontos)} options={chartOptionsPontosPorDia} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            ) : (null)}

        </div>
    );
};

export default GraficoFidelidade;